<template>
  <v-container fluid fill-height>
    <v-toolbar height="34" flat color="expansionPanels" class="my-1">
      <v-spacer />
      <v-toolbar-title class="black--text">Billboards List</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-row align="center" justify="center">
      <v-col>
        <v-data-table
          :items="getBillboards"
          :headers="headers"
          :search="search"
          sort-by="name"
          class="elevation-1 pa-3"
          :loading="!getBillboards.length"
          loading-text="Fetching Billboards... Please wait"
        >
          <template slot="top">
            <v-toolbar flat>
              <v-btn color="expansionPanels" @click="fetchAllBbs">
                Refresh
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

              <v-spacer></v-spacer>

              <v-responsive max-width="90%">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  full-width
                ></v-text-field>
              </v-responsive>

              <v-dialog v-model="deleteDialog" max-width="500px">
                <v-card>
                  <v-progress-linear
                    color="deep-purple accent-4"
                    :active="loading"
                    :indeterminate="loading"
                  ></v-progress-linear>

                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this
                    Billboard?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteBbConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.images`]="{ item }">
            <div class="p-2">
              <div v-viewer class="images">
                <img
                  class="image"
                  v-for="img in item.images"
                  :key="img.id"
                  :src="img.path"
                  :lazy-src="img.path"
                />
              </div>
            </div>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <billboard-form operation="edit" :currentBillboard="{ ...item }">
              <template v-slot:default> mdi-pencil</template>
            </billboard-form>

            <v-icon color="error" title="Delete" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" @click="fetchAllBbs">
              Refresh
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <alert></alert>
  </v-container>
</template>

<script>
import BillboardForm from "@/components/billboard/BillboardForm.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("billboards");

export default {
  data() {
    return {
      search: "",
      dialog: false,
      deleteDialog: false,
      error: null,
      loading: false,
      imgIdx: null,

      headers: [
        {
          text: "ID",
          align: "start",
          value: "bb_id",
        },
        {
          text: "Image",
          sortable: false,
          value: "images",
        },
        { text: "Lighting", value: "lighting" },
        { text: "Length (Metres)", value: "length" },
        { text: "Width (Metres)", value: "width" },
        { text: "Height", value: "height" },
        { text: "Clutter", value: "clutter" },
        { text: "Medium", value: "medium" },
        { text: "Brand", value: "brand_name" },
        { text: "Picture Taken", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  components: { BillboardForm },

  computed: {
    ...mapGetters(["getBillboards"]),
  },

  methods: {
    ...mapActions(["fetchAllBbs", "deleteBB"]),

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toDateString();
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    deleteItem(bb) {
      this.billboard_id = bb.id;
      this.deleteDialog = true;
      this.loading = true;
    },

    deleteBbConfirm() {
      this.deleteBB(this.billboard.id);
      this.closeDelete();
      this.loading = false;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    await this.fetchAllBbs();
  },
};
</script>

<style scoped>
.v-data-table__expanded__content {
  background-color: antiquewhite;
}

.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
</style>
